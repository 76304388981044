import { combineReducers, createStore, applyMiddleware } from 'redux';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import * as Sentry from '@sentry/nextjs';
import workflows from './modules/workflows';
import websocket from './modules/websocket';
import resources from './modules/resources';
import preprocessing from './modules/preprocessing';
import login from './modules/login';
import ui from './modules/ui';
import accounts from './modules/accounts';
import visualizations from './modules/visualizations';
import models from './modules/models';
import wizard from './modules/wizard';
import wellTie from './modules/wellTie';
import workflowLogs from './modules/workflowLogs';
import { USER_LOGOUT } from './modules/login/login.types';

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const combinedReducer = combineReducers({
  workflows,
  websocket,
  resources,
  preprocessing,
  ui,
  login,
  accounts,
  visualizations,
  models,
  wizard,
  wellTie,
  workflowLogs
});

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload // apply delta from hydration
    };
    return nextState;
  }

  if (action.type === USER_LOGOUT) {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

const setUserContextOnSentry = (userDetails) => {
  Sentry.setContext('userDetails', userDetails);
};

const loadUserDetails = () => {
  try {
    const serialState = window.localStorage.getItem('userDetails');
    if (serialState === null) {
      return undefined;
    }
    const userDetails = JSON.parse(serialState);
    setUserContextOnSentry(userDetails);

    return {
      login: {
        userDetails
      }
    };
  } catch (err) {
    return undefined;
  }
};

const initStore = () => {
  return createStore(reducer, loadUserDetails(), bindMiddleware([thunkMiddleware]));
};

const store = createWrapper(initStore);
export default store;

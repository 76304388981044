import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateConnection, getMessage } from 'store/modules/websocket';
import { enqueueSnackbar } from 'store/modules/ui';
import { updateWorkflowStatus, updateNodeStatus } from 'store/modules/workflows';
import { WORKFLOW_COMPLETED, WORKFLOW_RUNNING, WORKFLOW_ERROR } from 'utils/constants';

export const WSProviderPropTypes = {};

const mappings = {
  NODE_STARTED: (payload) => `${payload.workflowDesignName} - "${payload.nodeTitle}" Started`,
  NODE_SUCCESS: (payload) => `${payload.workflowDesignName} - "${payload.nodeTitle}" Succeeded`,
  NODE_FAILURE: (payload) => `${payload.workflowDesignName} - "${payload.nodeTitle}" Failed`,
  WORKFLOW_STARTED: (payload) => `${payload.workflowDesignName} Started`,
  WORKFLOW_SUCCESS: (payload) => `${payload.workflowDesignName} Succeeded`,
  WORKFLOW_FAILURE: (payload) => `${payload.workflowDesignName} Failed`
};

const types = {
  NODE_STARTED: 'node',
  NODE_SUCCESS: 'node',
  NODE_FAILURE: 'node',
  WORKFLOW_STARTED: 'workflow',
  WORKFLOW_SUCCESS: 'workflow',
  WORKFLOW_FAILURE: 'workflow'
};

const composeNotificationMessage = (action, payload) => {
  return mappings[action](payload);
};

function WSProvider({ children }) {
  const user = useSelector((state) => state.login.userDetails);
  const ws = React.useRef(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    ws.current = new WebSocket(process.env.NEXT_PUBLIC_WS_URL);
    ws.current.onopen = () => {
      dispatch(updateConnection({ connected: true }));

      if (user) {
        const teamIds = user.teams.map((team) => team.id);
        ws.current.send(JSON.stringify({ setConnectionUserInfo: { userId: user.id, teamIds } }));
      } else {
        console.log('no user details found');
      }
    };
    ws.current.onclose = () => dispatch(updateConnection({ connected: false }));

    const endConnection = () => {
      ws.current.close();
      window.removeEventListener('beforeunload', beforeUnload);
    };

    const beforeUnload = (e) => {
      event.preventDefault();
      return endConnection();
    };

    const listenersSetup = () => {
      window.addEventListener('beforeunload', beforeUnload);
    };

    listenersSetup();
  }, []);

  React.useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      const message = JSON.parse(e.data);
      const { action, payload } = message;
      if (!action) {
        return;
      }
      dispatch(enqueueSnackbar({ message: composeNotificationMessage(action, payload), variant: 'info' }));
      if (types[action] === 'workflow') {
        let status = payload.status;
        if (action === 'WORKFLOW_STARTED') status = WORKFLOW_RUNNING;
        if (action === 'WORKFLOW_SUCCESS') status = WORKFLOW_COMPLETED;
        if (action === 'WORKFLOW_FAILURE') status = WORKFLOW_ERROR;
        dispatch(
          updateWorkflowStatus({
            workflowId: payload.workflowDesignId,
            executionId: payload.workflowExecutionId,
            status: status,
            createdAt: payload.createdAt
          })
        );
      }
      if (types[action] === 'node') {
        dispatch(
          updateNodeStatus({
            workflowId: payload.workflowDesignId,
            executionId: payload.workflowExecutionId,
            nodeId: payload.nodeId,
            status: payload.status,
            createdAt: payload.createdAt
          })
        );
      }
    };
  }, []);

  return children;
}
WSProvider.propTypes = WSProviderPropTypes;

WSProvider.defaultProps = {};

export default WSProvider;

import * as types from './resources.types';

export const initialState = {
  items: [],
  uploads: {},
  loading: false,
  metadata: {},
  error: null
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.UPLOAD_RESOURCE_REQUEST: {
      return {
        ...state
      };
    }
    case types.UPLOAD_RESOURCE_SUCCESS: {
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [payload.key]: { name: payload.name, percentage: 100, ready: true, parent: payload.parent }
        }
      };
    }
    case types.UPLOAD_RESOURCE_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case types.UPDATE_UPLOAD_STATUS: {
      const parts = {
        ...(state.uploads[payload.key] || {}).parts,
        [payload.partNumber]: payload.uploaded
      };

      const currentProgress = Object.values(parts).reduce((accum, part) => accum + part, 0);
      const percentage = (currentProgress * 100) / payload.fileSize;
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [payload.key]: {
            name: payload.name,
            parts,
            percentage,
            ready: false
          }
        }
      };
    }
    case types.DOWNLOAD_RESOURCE_REQUEST:
    case types.CREATE_FOLDER_REQUEST:
    case types.GET_RESOURCES_REQUEST:
    case types.GET_METADATA_REQUEST:
    case types.DELETE_RESOURCE_REQUEST:
    case types.UPDATE_RESOURCE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case types.GET_RESOURCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: payload.resources
      };
    }
    case types.ADD_SINGLE_RESOURCE_ITEM: {
      let updatedItems = state.items;
      updatedItems.push(payload.resource);
      return {
        ...state,
        items: updatedItems
      };
    }
    case types.CREATE_FOLDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: [...state.items, payload.resource]
      };
    }
    case types.DOWNLOAD_RESOURCE_SUCCESS:
    case types.DELETE_RESOURCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: payload.items
      };
    }
    case types.UPDATE_RESOURCE_SUCCESS: {
      const { key, filename } = payload.record;
      let updatedItems = state.items.map((item) => {
        if (item.s3Path == key) {
          let newKey = item.s3Path;
          newKey = newKey.replace(/\/$/, '');
          newKey = newKey.split('/');
          newKey.pop(-1);
          newKey.push(filename);
          newKey = newKey.join('/');
          newKey += '/';

          return { ...item, s3Path: newKey, filename };
        }

        return item;
      });

      return {
        ...state,
        loading: false,
        items: updatedItems
      };
    }
    case types.DOWNLOAD_RESOURCE_FAILURE:
    case types.CREATE_FOLDER_FAILURE:
    case types.GET_METADATA_FAILURE:
    case types.GET_RESOURCES_FAILURE:
    case types.DELETE_RESOURCE_FAILURE:
    case types.UPDATE_RESOURCE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    }
    case types.GET_METADATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        metadata: Object.keys(payload.metadata).length
          ? { ...state.metadata, [payload.metadata.s3Path]: payload.metadata }
          : state.metadata
      };
    }
    default:
      return state;
  }
}
